import { useEffect } from "react";
import { useRecoilState } from "recoil";
import SectionTitle from "../../../shared/ui/components/SectionTitle";
import PageLayout from "../../../shared/ui/layouts/PageLayout";
import { returnRootUrlBasedOnAppLocation } from "../../../shared/utils/checkIfIsInlocal";
import {
  exportAdsOptionsAtom,
  exportCaresOptionsAtom,
  exportConferencesOptionsAtom,
  exportOrdersOptionsAtom,
  exportStandsOptionsAtom,
  exportTicketsOptionsAtom,
  exportUsersOptionsAtom,
  exportVisitorsOptionsAtom,
  exportWorkshopOptionsAtom,
} from "../atoms/export-atoms";
import { CsvExport } from "../components/CsvExportButton";
import ExportItem from "../components/ExportItem";
import { ExportOptionType } from "../types/export";

const conferencesOptions: ExportOptionType[] = [
  {
    title: "Bulle",
    slug: "bulle",
  },
  {
    title: "Genève",
    slug: "geneve",
  },
  {
    title: "Morges",
    slug: "morges",
  },
  {
    title: "Aigle",
    slug: "aigle",
  },
  {
    title: "2023",
    slug: "2023",
  },
  {
    title: "2024",
    slug: "2024",
  },
  {
    title: "2025",
    slug: "2025",
  },
  // {
  //   title: "2025",
  //   slug: "2025",
  // },
  {
    title: "N° Commande",
    slug: "num_commande",
  },
  {
    title: "Salon",
    slug: "salon",
  },
  {
    title: "Client",
    slug: "client",
  },
  {
    title: "Nom du conférencier",
    slug: "nom_conferencier",
  },
  {
    title: "Prénom du conférencier",
    slug: "prenom_conferencier",
  },
  {
    title: "Titre de la conférence",
    slug: "titre_conference",
  },
  {
    title: "Description de la conférence",
    slug: "description_conference",
  },
  {
    title: "Créneau souhaité",
    slug: "creneau_souhaite",
  },
  {
    title: "Horaire validé",
    slug: "horaire_valide",
  },
  {
    title: "Salle",
    slug: "salle",
  },
  {
    title: "Stand",
    slug: "stand",
  },
  {
    title: "Statut de conférence",
    slug: "statut_conference",
  },
];

const workshopsOptions: ExportOptionType[] = [
  {
    title: "Bulle",
    slug: "bulle",
  },
  {
    title: "Genève",
    slug: "geneve",
  },
  {
    title: "Morges",
    slug: "morges",
  },
  {
    title: "Aigle",
    slug: "Aigle",
  },
  {
    title: "2023",
    slug: "2023",
  },
  {
    title: "2024",
    slug: "2024",
  },
  {
    title: "2025",
    slug: "2025",
  },
  // {
  //   title: "2025",
  //   slug: "2025",
  // },
  {
    title: "N° Commande",
    slug: "num_commande",
  },
  {
    title: "Salon",
    slug: "salon",
  },
  {
    title: "Client",
    slug: "client",
  },
  {
    title: "Titre de l'atelier",
    slug: "titre_atelier",
  },
  {
    title: "Description de l'atelier",
    slug: "description_atelier",
  },
  {
    title: "Informations et inscriptions",
    slug: "informations_et_inscriptions",
  },
  {
    title: "Créneaux et salles",
    slug: "creneaux_valides",
  },
  {
    title: "Salle",
    slug: "salle",
  },
  {
    title: "Statut de l'atelier",
    slug: "statut_atelier",
  },
];

const careOptions: ExportOptionType[] = [
  {
    title: "Bulle",
    slug: "bulle",
  },
  {
    title: "Genève",
    slug: "geneve",
  },
  {
    title: "Morges",
    slug: "morges",
  },
  {
    title: "Aigle",
    slug: "Aigle",
  },
  {
    title: "2023",
    slug: "2023",
  },
  {
    title: "2024",
    slug: "2024",
  },
  {
    title: "2025",
    slug: "2025",
  },
  // {
  //   title: "2025",
  //   slug: "2025",
  // },
  {
    title: "N° Commande",
    slug: "num_commande",
  },
  {
    title: "Salon",
    slug: "salon",
  },
  {
    title: "Client",
    slug: "client",
  },
  {
    title: "Titre soin",
    slug: "titre_soin",
  },
  {
    title: "Description soin",
    slug: "description_soin",
  },
  {
    title: "Créneaux souhaités",
    slug: "creneaux_souhaites",
  },
  {
    title: "Créneaux validés",
    slug: "creneaux_valides",
  },
  {
    title: "Assurance",
    slug: "assurance",
  },
  {
    title: "Statut d'Espace Soins",
    slug: "statut_espace_soins",
  },
];

const standsOptions: ExportOptionType[] = [
  {
    title: "Bulle",
    slug: "bulle",
  },
  {
    title: "Genève",
    slug: "geneve",
  },
  {
    title: "Morges",
    slug: "morges",
  },
  {
    title: "Aigle",
    slug: "Aigle",
  },
  {
    title: "2023",
    slug: "2023",
  },
  {
    title: "2024",
    slug: "2024",
  },
  {
    title: "2025",
    slug: "2025",
  },
  // {
  //   title: "2025",
  //   slug: "2025",
  // },
  {
    title: "N° Commande",
    slug: "num_commande",
  },
  {
    title: "Salon",
    slug: "salon",
  },
  {
    title: "Client",
    slug: "client",
  },
  {
    title: "Type de stand",
    slug: "type_stand",
  },
  {
    title: "Parking",
    slug: "parking",
  },
  {
    title: "Meilleure visibilité",
    slug: "meilleure_visibilite",
  },
  {
    title: "Publicité dans le programme",
    slug: "publicite_programme",
  },
  {
    title: "N° stand",
    slug: "num_stand",
  },
  {
    title: "Type d'activité",
    slug: "type_activite",
  },
  {
    title: "Statut de stand",
    slug: "statut_stand",
  },
];

const ordersOptions: ExportOptionType[] = [
  {
    title: "Bulle",
    slug: "bulle",
  },
  {
    title: "Genève",
    slug: "geneve",
  },
  {
    title: "Morges",
    slug: "morges",
  },
  {
    title: "Aigle",
    slug: "Aigle",
  },
  {
    title: "2023",
    slug: "2023",
  },
  {
    title: "2024",
    slug: "2024",
  },
  {
    title: "2025",
    slug: "2025",
  },
  // {
  //   title: "2025",
  //   slug: "2025",
  // },
  {
    title: "N° Commande",
    slug: "num_commande",
  },
  {
    title: "Salon",
    slug: "salon",
  },
  {
    title: "Client",
    slug: "client",
  },
  {
    title: "Date de commande",
    slug: "date_commande",
  },
  {
    title: "Date de facture",
    slug: "date_facture",
  },
  {
    title: "Statut de commande",
    slug: "statut_commande",
  },
  {
    title: "Total HT",
    slug: "total_ht",
  },
  {
    title: "Total TTC",
    slug: "total_ttc",
  },
  {
    title: "Total TVA",
    slug: "total_tva",
  },
  {
    title: "Numéro de stand",
    slug: "num_stand",
  },
  {
    title: "Commentaire de facture",
    slug: "commentaire_facture",
  },
];

const adsOptions: ExportOptionType[] = [
  {
    title: "Bulle",
    slug: "bulle",
  },
  {
    title: "Genève",
    slug: "geneve",
  },
  {
    title: "Morges",
    slug: "morges",
  },
  {
    title: "Aigle",
    slug: "Aigle",
  },
  {
    title: "2023",
    slug: "2023",
  },
  {
    title: "2024",
    slug: "2024",
  },
  {
    title: "2025",
    slug: "2025",
  },
  {
    title: "N° Commande",
    slug: "num_commande",
  },
  {
    title: "Salon",
    slug: "salon",
  },
  {
    title: "Client",
    slug: "client",
  },
  {
    title: "Publicité dans le programme",
    slug: "publicite_programme",
  },
  {
    title: "Meilleure visibilité",
    slug: "meilleure_visibilite",
  },
  {
    title: "Type de stand",
    slug: "type_stand",
  },
  {
    title: "N° stand",
    slug: "num_stand",
  },
  {
    title: "Statut de stand",
    slug: "statut_stand",
  },
];
const usersOptions: ExportOptionType[] = [
  {
    title: "Identifiant",
    slug: "identifiant",
  },
  {
    title: "Raison sociale",
    slug: "raison_sociale",
  },
  {
    title: "Nom",
    slug: "nom",
  },
  {
    title: "Prénom",
    slug: "prenom",
  },
  {
    title: "Email",
    slug: "email",
  },
  {
    title: "Téléphone de facturation",
    slug: "telephone",
  },
  {
    title: "Téléphone d'activité",
    slug: "telephone_activite",
  },
  {
    title: "Site web",
    slug: "site_web",
  },
  {
    title: "Type d'activité",
    slug: "type_activite",
  },
  {
    title: "Newsletter",
    slug: "newsletter",
  },
  {
    title: "Déjà venu au salon",
    slug: "deja_venu",
  },
  {
    title: "Commentaires administrateur",
    slug: "commentaires_admin",
  },
  {
    title: "Adresse de facturation",
    slug: "adresse_facturation",
  },
  {
    title: "Complément adresse de facturation",
    slug: "complement_adresse_facturation",
  },
  {
    title: "Code postal de facturation",
    slug: "code_postal_facturation",
  },
  {
    title: "Ville de facturation",
    slug: "ville_facturation",
  },
  {
    title: "Pays de facturation",
    slug: "pays_facturation",
  },
  {
    title: "Adresse de l'activité",
    slug: "adresse_activite",
  },
  {
    title: "Complément adresse de l'activité",
    slug: "complement_adresse_activite",
  },
  {
    title: "Code postal de l'activité",
    slug: "code_postal_activite",
  },
  {
    title: "Ville de l'activité",
    slug: "ville_activite",
  },
  {
    title: "Pays de l'activité",
    slug: "pays_activite",
  },
];

const ticketsOptions: ExportOptionType[] = [
  {
    title: "Bulle",
    slug: "bulle",
  },
  {
    title: "Genève",
    slug: "geneve",
  },
  {
    title: "Morges",
    slug: "morges",
  },
  {
    title: "Aigle",
    slug: "Aigle",
  },
  {
    title: "2023",
    slug: "2023",
  },
  {
    title: "2024",
    slug: "2024",
  },
  {
    title: "2025",
    slug: "2025",
  },
  {
    title: "Nom",
    slug: "last_name",
  },
  {
    title: "Prénom",
    slug: "first_name",
  },
  {
    title: "Email",
    slug: "email",
  },
  {
    title: "Téléphone de facturation",
    slug: "phone",
  },
  {
    title: "Téléphone d'activité",
    slug: "telephone_activite",
  },
  {
    title: "N° Commande",
    slug: "order_id",
  },
  {
    title: "N° Billet",
    slug: "ticket_id",
  },
  {
    title: "Produit",
    slug: "product_name",
  },
  {
    title: "Date scan",
    slug: "scanned_date",
  },
  {
    title: "Salon",
    slug: "exhibition",
  },
  {
    title: "URL Billet",
    slug: "ticket_pdf_url",
  },
  {
    title: "Code promo",
    slug: "promo_code",
  },
];

const AnalyticsScreen = () => {
  let token = "";
  if (localStorage.getItem("token-dashboard")) {
    token = JSON.parse(localStorage.getItem("token-dashboard") as string);
  }
  const [exportConferencesOptions, setExportConferencesOptions] =
    useRecoilState(exportConferencesOptionsAtom);
  const [exportWorkshopsOptions, setExportWorkshopsOptions] = useRecoilState(
    exportWorkshopOptionsAtom
  );
  const [exportCaresOptions, setExportCaresOptions] = useRecoilState(
    exportCaresOptionsAtom
  );
  const [exportStandsOptions, setExportStandsOptions] = useRecoilState(
    exportStandsOptionsAtom
  );
  const [exportOrdersOptions, setExportOrdersOptions] = useRecoilState(
    exportOrdersOptionsAtom
  );
  const [exportUsersOptions, setExportUsersOptions] = useRecoilState(
    exportUsersOptionsAtom
  );
  const [exportAdsOptions, setExportAdsOptions] =
    useRecoilState(exportAdsOptionsAtom);
  const [exportTicketsOptions, setExportTicketsOptions] = useRecoilState(
    exportTicketsOptionsAtom
  );
  const [exportVisitorsOptions, setExportVisitorsOptions] = useRecoilState(
    exportVisitorsOptionsAtom
  );
  //   Get current date and time with the following format : 24/12/20223-17:23
  const date = new Date();
  const day = date.getDate();
  //   const month = date.getMonth() + 1;
  const month = date.toLocaleString("default", { month: "2-digit" });
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const currentDate = `${day}${month}${year}-${hours}h${minutes}`;

  useEffect(() => {
    // Add all conferences options slugs to exportConferencesOptions
    setExportConferencesOptions(
      conferencesOptions.map((option) => option.slug)
    );
    // Add all workshops options slugs to exportWorkshopsOptions
    setExportWorkshopsOptions(workshopsOptions.map((option) => option.slug));
    // Add all cares options slugs to exportCaresOptions
    setExportCaresOptions(careOptions.map((option) => option.slug));
    // Add all stands options slugs to exportStandsOptions
    setExportStandsOptions(standsOptions.map((option) => option.slug));
    // Add all orders options slugs to exportOrdersOptions
    setExportOrdersOptions(ordersOptions.map((option) => option.slug));
    // Add all ads options slugs to exportAdsOptions
    setExportAdsOptions(adsOptions.map((option) => option.slug));
    // Add all users options slugs to exportUsersOptions
    setExportUsersOptions(usersOptions.map((option) => option.slug));
    // Add all tickets options slugs to exportTicketsOptions
    setExportTicketsOptions(ticketsOptions.map((option) => option.slug));
    // Add all visitors options slugs to exportVisitorsOptions
    setExportVisitorsOptions(usersOptions.map((option) => option.slug));
  }, []);

  useEffect(() => {
    // console.log("Conf Options : ", exportConferencesOptions);
    // console.log("Ateliers Options : ", exportWorkshopsOptions);
    // console.log("Espace Soins Options : ", exportCaresOptions);
    // console.log("Stands Options : ", exportStandsOptions);
    // console.log("Commandes Options : ", exportOrdersOptions);
    // console.log("Pub Options : ", exportAdsOptions);
    console.log("Users Options : ", exportUsersOptions);
  }, [
    // exportConferencesOptions,
    // exportWorkshopsOptions,
    // exportCaresOptions,
    // exportStandsOptions,
    // exportOrdersOptions,
    // exportAdsOptions,
    exportUsersOptions,
  ]);
  return (
    <PageLayout>
      {/* <CSVLink data={listOfUsers} asyncOnClick={true} onClick={getUsers}>
        {loading ? "Loading..." : "Download me"}
      </CSVLink> */}
      <SectionTitle title="Export de données" />
      <div className="flex flex-col gap-y-8">
        <ExportItem
          title="Ateliers"
          slug="ateliers"
          atom={exportWorkshopOptionsAtom}
          options={workshopsOptions}
          usersOptions={usersOptions}
          ExportButtonComponent={
            <CsvExport
              filename={`ateliers_${currentDate}.xlsx`}
              url={`${returnRootUrlBasedOnAppLocation()}admin-dashboard/v1/extract-workshops-data`}
              chosen_headers={exportWorkshopsOptions}
            />
          }
        />
        {/* <div className="flex flex-col items-center justify-between gap-y-4 md:flex-row">
          <span className="text-xl font-semibold">Commandes</span>
          <span className="cursor-not-allowed rounded-lg bg-gray-300 px-10 py-4 text-[16px] text-base font-bold uppercase text-white">
            A venir
          </span>
        </div> */}
        <ExportItem
          title="Commandes"
          slug="commandes"
          options={ordersOptions}
          usersOptions={usersOptions}
          atom={exportOrdersOptionsAtom}
          ExportButtonComponent={
            <CsvExport
              filename={`commandes_${currentDate}.xlsx`}
              url={`${returnRootUrlBasedOnAppLocation()}admin-dashboard/v1/extract-orders-data`}
              chosen_headers={exportOrdersOptions}
            />
          }
        />
        <ExportItem
          title="Conférences"
          slug="conferences"
          atom={exportConferencesOptionsAtom}
          options={conferencesOptions}
          usersOptions={usersOptions}
          ExportButtonComponent={
            <CsvExport
              filename={`conferences_${currentDate}.xlsx`}
              url={`${returnRootUrlBasedOnAppLocation()}admin-dashboard/v1/extract-conference-data`}
              chosen_headers={exportConferencesOptions}
            />
          }
        />
        <ExportItem
          title="Espaces Soins"
          slug="espaces-soins"
          options={careOptions}
          usersOptions={usersOptions}
          atom={exportCaresOptionsAtom}
          ExportButtonComponent={
            <CsvExport
              filename={`espaces-soins_${currentDate}.xlsx`}
              url={`${returnRootUrlBasedOnAppLocation()}admin-dashboard/v1/extract-cares-data`}
              chosen_headers={exportCaresOptions}
            />
          }
        />
        <ExportItem
          title="Publicités"
          slug="publicites"
          options={adsOptions}
          usersOptions={usersOptions}
          atom={exportAdsOptionsAtom}
          ExportButtonComponent={
            <CsvExport
              filename={`publicites_${currentDate}.xlsx`}
              url={`${returnRootUrlBasedOnAppLocation()}admin-dashboard/v1/extract-ads-data`}
              chosen_headers={exportAdsOptions}
            />
          }
        />
        <ExportItem
          title="Stands"
          slug="stands"
          options={standsOptions}
          usersOptions={usersOptions}
          atom={exportStandsOptionsAtom}
          ExportButtonComponent={
            <CsvExport
              filename={`stands_${currentDate}.xlsx`}
              url={`${returnRootUrlBasedOnAppLocation()}admin-dashboard/v1/extract-stands-data`}
              chosen_headers={exportStandsOptions}
            />
          }
        />
        <ExportItem
          title="Clients professionnels"
          slug="clients"
          options={usersOptions}
          usersOptions={null}
          atom={exportUsersOptionsAtom}
          ExportButtonComponent={
            <CsvExport
              filename={`clients_${currentDate}.xlsx`}
              url={`${returnRootUrlBasedOnAppLocation()}admin-dashboard/v1/extract-users-data`}
              chosen_headers={exportUsersOptions}
            />
          }
        />
        <ExportItem
          title="Visiteurs"
          slug="visiteurs"
          options={usersOptions}
          usersOptions={null}
          atom={exportVisitorsOptionsAtom}
          ExportButtonComponent={
            <CsvExport
              filename={`visiteurs_${currentDate}.xlsx`}
              url={`${returnRootUrlBasedOnAppLocation()}admin-dashboard/v1/extract-visitors-data`}
              chosen_headers={exportVisitorsOptions}
            />
          }
        />
        <ExportItem
          title="Billets"
          slug="billets"
          options={ticketsOptions}
          usersOptions={null}
          atom={exportTicketsOptionsAtom}
          ExportButtonComponent={
            <CsvExport
              filename={`billets_${currentDate}.xlsx`}
              url={`${returnRootUrlBasedOnAppLocation()}admin-dashboard/v1/extract-tickets-data`}
              chosen_headers={exportTicketsOptions}
            />
          }
        />
      </div>
    </PageLayout>
  );
};

export default AnalyticsScreen;
